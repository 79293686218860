import * as THREE from 'three'
import { Fragment } from 'react'
import { useGLTF, useTexture, OrbitControls, shaderMaterial } from '@react-three/drei'
import { useControls } from 'leva'
import { extend } from '@react-three/fiber'
import room3dVertexShader from './shaders/room3d/vertex.js'
import room3dFragmentShader from './shaders/room3d/fragment.js'

export default function Room3d() {

    // Load model
    const {nodes} = useGLTF('./resources/models/NewRoom3D.glb')

    // Load material
    const dayTexture = useTexture('./resources/textures/bakedDayLight.jpg')
    dayTexture.flipY = false
    const nightTexture = useTexture('./resources/textures/baked.jpg')
    nightTexture.flipY = false
    const colorlessTexture = useTexture('./resources/textures/bakedColorless.jpg')
    colorlessTexture.flipY = false
    
    const {nightMix, colorlessMix} = useControls({
        nightMix: {
            value: 0,
            min: 0,
            max: 1
        },
        colorlessMix: {
            value: 0,
            min: 0,
            max: 1
        }
    })

    const Room3dMaterial = shaderMaterial(
        {
            uNightMix: 0,
            uColorlessMix: 0,
            uBakedDayTexture: dayTexture,
            uBakedNightTexture: nightTexture,
            uBakedColorlessTexture: colorlessTexture
        },
        room3dVertexShader,
        room3dFragmentShader
    )
    
    extend({Room3dMaterial})

    return <Fragment>
        <group position={[4, 1.2, 0]}>
            <mesh geometry={nodes.Merged.geometry}>
                <room3dMaterial uNightMix={nightMix} uColorlessMix={colorlessMix} />
            </mesh>
        </group>
    
        <OrbitControls 
            minAzimuthAngle={-Math.PI / 2} 
            maxAzimuthAngle={Math.PI / 4 - 0.92} 
            maxPolarAngle={Math.PI / 2}
            panSpeed={0.5}
            rotateSpeed={0.3}
            minDistance={1}
            enablePan={false}
            makeDefault
        />
    </Fragment>
}