export default /* glsl */ `uniform sampler2D uBakedDayTexture;
uniform sampler2D uBakedNightTexture;
uniform sampler2D uBakedColorlessTexture;

uniform float uNightMix;
uniform float uColorlessMix;

varying vec2 vUv;

void main() {
    vec3 bakedDayColor = texture2D(uBakedDayTexture, vUv).rgb;
    vec3 bakedNightColor = texture2D(uBakedNightTexture, vUv).rgb;
    vec3 bakedColorless = texture2D(uBakedColorlessTexture, vUv).rgb;
    vec3 bakedColor = mix(bakedDayColor, bakedNightColor, uNightMix);
    bakedColor = mix(bakedColor, bakedColorless, uColorlessMix);

    gl_FragColor = vec4(bakedColor, 1.0);
}`