import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import { StrictMode } from 'react'
import { Leva } from 'leva'
import Room3d from './room3d/Room3d.jsx'
import Navigator from './Navigator.jsx'

const root = ReactDOM.createRoot(document.querySelector('#app'))

root.render(
    <StrictMode>
        <Leva />
        <Canvas 
            camera={{fov: 28, position: [-20, 12, 19]}}
        >
            <color args={['black']} attach="background" />
            <Room3d />
        </Canvas>
        <Navigator />
    </StrictMode>
)